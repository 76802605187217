var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-center","centered":"","title":"Добавить слайдер"},on:{"change":_vm.hideModal,"show":_vm.showModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"res-w-100",attrs:{"variant":"outline-secondary","type":"reset"},on:{"click":function($event){return cancel()}}},[_vm._v(" Отмена ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"res-mt-2 res-w-100",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("Добавить")])])]}}]),model:{value:(_vm.isAddNewUserModalActive),callback:function ($$v) {_vm.isAddNewUserModalActive=$$v},expression:"isAddNewUserModalActive"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Название","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Название","label-for":"new-city-title"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"id":"new-city-title","placeholder":"Название"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Изображение Ru","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Изображение Ru","label-for":"new-slider-img-ru"}},[_c('b-form-file',{attrs:{"id":"new-slider-img-ru","accept":"image/png, image/jpeg, image/tiff","placeholder":"Выберите изображение...","drop-placeholder":"Drop file here..."},on:{"change":function($event){return _vm.handleFileUploadRu($event)}},model:{value:(_vm.imgRu),callback:function ($$v) {_vm.imgRu=$$v},expression:"imgRu"}}),_c('div',[(_vm.imageRequiredRu === 1)?_c('small',{staticClass:"text-danger"},[_vm._v("Поле Лого поддерживает только файлы в формате .jpg, .png, .tiff")]):_vm._e()]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('div',[_c('span',{staticClass:"sm-size"},[_vm._v("(Поддерживаются файлы в формате .jpg, .png, .tiff) ")])])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Изображение Uz","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Изображение Uz","label-for":"new-slider-img-uz"}},[_c('b-form-file',{attrs:{"id":"new-slider-img-uz","accept":"image/png, image/jpeg, image/tiff","placeholder":"Выберите изображение...","drop-placeholder":"Drop file here..."},on:{"change":function($event){return _vm.handleFileUploadUz($event)}},model:{value:(_vm.imgUz),callback:function ($$v) {_vm.imgUz=$$v},expression:"imgUz"}}),_c('div',[(_vm.imageRequiredUz === 1)?_c('small',{staticClass:"text-danger"},[_vm._v("Поле Лого поддерживает только файлы в формате .jpg, .png, .tiff")]):_vm._e()]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('div',[_c('span',{staticClass:"sm-size"},[_vm._v("(Поддерживаются файлы в формате .jpg, .png, .tiff) ")])])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Изображение En","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Изображение En","label-for":"new-slider-img-en"}},[_c('b-form-file',{attrs:{"id":"new-slider-img-en","accept":"image/png, image/jpeg, image/tiff","placeholder":"Выберите изображение...","drop-placeholder":"Drop file here..."},on:{"change":function($event){return _vm.handleFileUploadEn($event)}},model:{value:(_vm.imgEn),callback:function ($$v) {_vm.imgEn=$$v},expression:"imgEn"}}),_c('div',[(_vm.imageRequiredEn === 1)?_c('small',{staticClass:"text-danger"},[_vm._v("Поле Лого поддерживает только файлы в формате .jpg, .png, .tiff")]):_vm._e()]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('div',[_c('span',{staticClass:"sm-size"},[_vm._v("(Поддерживаются файлы в формате .jpg, .png, .tiff) ")])])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Ссылка на сайт","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Ссылка на сайт","label-for":"new-slider-siteUrl"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"id":"new-slider-siteUrl","placeholder":"Ссылка на сайт"},model:{value:(_vm.siteUrl),callback:function ($$v) {_vm.siteUrl=$$v},expression:"siteUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Тип","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Тип","label-for":"new-slider-type"}},[_c('v-select',{attrs:{"id":"new-slider-type","placeholder":"Выберите тип слайдера","state":errors.length > 0 ? false : null,"label":"title","options":_vm.intentTypes},model:{value:(_vm.intentType),callback:function ($$v) {_vm.intentType=$$v},expression:"intentType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Цвет текста","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Цвет текста","label-for":"new-slider-textColor"}},[_c('v-select',{attrs:{"id":"new-slider-textColor","placeholder":"Выберите цвет текста","state":errors.length > 0 ? false : null,"label":"title","options":_vm.textColors},model:{value:(_vm.textColor),callback:function ($$v) {_vm.textColor=$$v},expression:"textColor"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Кликабельность","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Кликабельность","label-for":"new-slider-clickable"}},[_c('v-select',{attrs:{"id":"new-slider-clickable","placeholder":"Выберите кликабельность","state":errors.length > 0 ? false : null,"label":"title","options":_vm.clickableOptions},model:{value:(_vm.isClickable),callback:function ($$v) {_vm.isClickable=$$v},expression:"isClickable"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Мерчант","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Мерчант","label-for":"new-slider-merchant"}},[_c('v-select',{attrs:{"id":"new-slider-merchant","placeholder":"Введите название мерчанта","state":errors.length > 0 ? false : null,"label":"name","options":_vm.sortedMerchants},on:{"search":_vm.getMerchants},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Пусто ")]}}],null,true),model:{value:(_vm.selectedMerchant),callback:function ($$v) {_vm.selectedMerchant=$$v},expression:"selectedMerchant"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Предложение","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Предложение","label-for":"new-slider-offer"}},[_c('v-select',{attrs:{"id":"new-slider-offer","placeholder":"Введите название предложения","state":errors.length > 0 ? false : null,"label":"titleRu","options":_vm.sortedOffers},on:{"search":_vm.getOffers},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Пусто ")]}}],null,true),model:{value:(_vm.selectedOffer),callback:function ($$v) {_vm.selectedOffer=$$v},expression:"selectedOffer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }